import APIProvider from "@api/APIProvider";
import SiteContext from "context/SiteContext";
import {
  useEffect,
  useContext,
  useState
} from "react";

export default function useLocation() {
  const { site } = useContext(SiteContext);

  const [ location, setLocation ] = useState();

  useEffect(() => {
    let isSubscribed = true;
    
    async function fetchData() {
      let location = await APIProvider.getLocation(site.user_id);

      if(isSubscribed) {
        setLocation(location)
      }
    }

    fetchData();

    return () => {
      isSubscribed = false;
    }
  }, [])

  return {
    location
  }
}
