import Link from "next/link";
import Card from 'honeyjar/Card';
import Button from "honeyjar/Button";
import SiteContext from "context/SiteContext";
import { useContext } from "react";

import SetupList from "honeyjar/SetupList";
import SetupListItem from "honeyjar/SetupListItem";
import useLocation from "effects/useLocation";

const RESTAURANT_STEPS = [
    {
        label: 'Setup Business Information',
        btnLabel: "Update Info",
        link: "/business-info",
        done: true
    },
    {
        label: 'Manage your Location',
        btnLabel: "Edit Location",
        link: "locations",
        done: true
    },
    {
        label: "Edit your Restaurant Menu",
        btnLabel: "Update Menu",
        link: "edit-menu"
    }
];   

/*

    {
        label: "Get Found on Google",
        link: "/seo",
        btnLabel: "Get Started",
    }
*/

export default function QuickStartCard() {
    const { site } = useContext(SiteContext);

    const {
        location
      } = useLocation();

    // get the number of locations
    // get the business information
    // get the opening closing / hours

    return (
        <Card 
            title="Restaurant Quick Start"
            actions={ 
                <div className="flex items-center hidden">
                    <div className="text-sm">0 / { RESTAURANT_STEPS.length } completed</div>
                    <div className="w-[100px] bg-slate-200 h-[4px] rounded ml-2">
                        <div className="bg-blue-700 h-full rounded" style={{ width: `${ (2 / RESTAURANT_STEPS.length) * 100 }%` }}></div>
                    </div>
                </div>
            }>
                <div>
                <SetupList>
                    <SetupListItem
                        title="Add Location"
                        btnLabel="Manage Location"
                        href="/locations"
                        completed={ location?.id } />

                    <SetupListItem
                        title="Add your online menu"
                        btnLabel="Edit Menu"
                        href="/edit-menu"
                        completed={ site?.properties?.collection_ids?.length } />
                </SetupList>
            </div>
        </Card>
      )
}