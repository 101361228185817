import Link from "next/link";
import Icon from '@mdi/react';
import { mdiCheck, mdiCircleMedium, mdiOpenInNew, mdiAlertCircleOutline  } from '@mdi/js';
import Button from "./Button";

export default function SetupListItem(props) {
    let {
      title,
      btnLabel,
      completed,
      href="",
      onClick
    } = props;
  
    return (
        <li>
            <Link 
              href={ href } 
              onClick={onClick} 
              className={`border px-4 py-3 w-full flex justify-between items-center mb-4 last-child:mb-0 hover:bg-slate-100 transition-colors` }>
                <div className="flex items-center">
                  
                  <div>
                    <Icon 
                      path={ completed ? mdiCheck : mdiAlertCircleOutline  }
                      className={ `mr-2 ${ completed ? 'text-green-700' : 'text-rose-700' }` }
                      size={ 1 } />
                  </div>
                  <span className={ completed ? 'text-slate-600' : 'font-semibold' }>
                    { title }
                  </span>
                </div>
                <div>
                    <Button variant={ completed ? "outlined" : "" } size="small">
                        { btnLabel }
                    </Button>
                </div>
            </Link>
        </li>
    )
  }