import {
    useEffect,
    useState,
    useContext,
    useCallback
} from "react";

import Dashboard from 'components/core/Dashboard'

import QuickStartCard from "components/cards/QuickStartCard";
import SiteContext from "context/SiteContext";

export default function HomePage(props) {
  const { site } = useContext(SiteContext);

    return (
      <Dashboard title={ `Welcome, ${ site.name || "" }`  }>
          <QuickStartCard />
      </Dashboard>
    )
}